import Work1 from "../../assets/royalbrother.png";
import Work2 from "../../assets/lenskart.png";
import Work3 from "../../assets/work3.png";
import Work4 from "../../assets/work4.jpg";
import Work5 from "../../assets/work5.jpg";

export const projectsData = [
  {
    id: 1,
    image: Work1,
    title: "Royal brothers",
    content1:"Royal brother is an e-commerce  company and bike rental",
    content2:"bike accessories, and  products across websites...",
    category: "web",
    deploy_link:"https://royalbrothers.pages.dev/",
    github_link:"https://github.com/Rajatsinha05/royalbrothers"
  },
  {
    id: 2,
    image: Work2,
    title: "Lenskart",
    content1:"Lenskart is India’s most trusted eye glasses seller,",
    content2:"for eye glasses and hygiene related products...",
    category: "web",
    deploy_link:"https://venerable-gumption-eac1fd.netlify.app/",
    github_link:"https://github.com/Vikasyadav3935/Lenskart-clone"
  },
  {
    id: 3,
    image: Work3,
    title: "Tata 1M",
    content1:"The site is focused on providing a market place ",
    content2:"for eye glasses and hygiene related products...",
    category: "web",
    deploy_link:"https://celebrated-brioche-bea6c1.netlify.app/index.html",
    github_link:"https://github.com/Mr-raaz/1Mg_Clone_Project"
  },

  {
    id: 3,
    image: Work3,
    title: "zomato",
    content1:"The site is focused on providing a place order online food  ",
    content2:" related products...",
    category: "web",
    deploy_link:"clone-zomato-project.netlify.app/",
    github_link:"https://github.com/Rajatsinha05/Zomato-clone"
  }
];

// projects
export const projectsNav = [
  {
    name: "all",
  },
  {
    name: "web",
  },
  {
    name: "app",
  },
  {
    name: "design",
  },
];
